<template lang="">
    <div>
        <b-form @submit.stop.prevent>
            <b-row>
                <b-col cols="6">
                    <b-form-group label="Nom du projet" label-for="input-brand-name">
                        <div v-if="projectsLoaded">
                            <b-form-select id="id" v-model="project_name" :options="projects" text-field="name" value-field="name" @change="switchSelected"></b-form-select>
                        </div>
                        <div v-else>Loading projects...</div>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group label="Develop / Feature / Version" label-for="input-wording-name" @change="switchSelected(event)">
                        <b-form-select id="input-brand" v-model="support_name" :options="supports" text-field="supportname" value-field="code"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" v-if="support_name === 'Feature' && project_name !== ''">
                    <b-form-group label="Nom de la Feature" label-for="input-wording-name">
                        <b-form-select id="input-brand" v-model="selectedBranche" :options="branches" text-field="name" value-field="name"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col v-else></b-col>
            </b-row>

            <b-row>
                <b-col cols="6" v-if="support_name === 'Version' && project_name !== ''">
                    <b-form-group label="Tags(version)" label-for="input-wording-name">
                        <b-form-select id="input-brand" v-model="selectedTags" :options="tags" text-field="name" value-field="name"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col v-else></b-col>
            </b-row>

            <b-row class="pb-4">
                <b-col>
                    <b-btn variant="success" class="d-flex ml-auto" @click="deploy" :disabled="loading">
                        <span v-if="loading">Chargement...</span>
                        <span v-else>Déployer</span>
                    </b-btn>
                </b-col>
            </b-row>
        </b-form>
        <div>
            <b-card no-body>
                <b-tabs card v-model="activeTab">
                    <div class="col-md-10 w-100 mx-auto">
                        <b-input class="ml-2" style="width: 97.5%" v-model="filter" placeholder="Filtrer sur la table..."></b-input>

                        <b-tab title="Liste de Ports & Versions">
                            <b-card-text>
                                <b-table v-if="activeTab === 0" cols="2" striped hover :items="portListAndVersions" :filter="filter"></b-table>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Logs Business_deploy">
                            <b-card-text>
                                <pre>
                                    {{ logs }}
                                </pre>
                            </b-card-text>
                        </b-tab>
                    </div>
                </b-tabs>
            </b-card>
        </div>
        <div v-if="loading" class="overlay">
            <div class="spinner">
            </div>
        </div>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
    components: {},
    data() {
        return {
            loading: false,
            projectsLoaded: false,
            projects: [],
            project_name: '',
            supports: ['Develop', 'Feature', 'Version'],
            support_name: '',
            branches: [],
            selectedBranche: '',
            portListAndVersions: [],
            versionList: [],
            activeTab: 0,
            filter: '',
            selectedTags: '',
            logs: '',
            tags: [],
        };
    },
    computed: {
        transformedVersionList() {
            return this.portList.map((item) => item[1]);
        },

        sortedBranches() {
            return this.branches.slice().sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                if (nameA < nameB) {
                    return -1;
                } else if (nameA > nameB) {
                    return 1;
                } else {
                    return 0;
                }
            });
        },
    },

    created() {
        this.fetchProjects();
        this.fetchPortListAndVersions();
    },

    methods: {
        async fetchProjects() {
            try {
                const res = await this.$store.dispatch('app/getProjectsList');
                this.projects = res.data.slice().sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();

                    if (nameA < nameB) {
                        return -1;
                    } else if (nameA > nameB) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                this.projectsLoaded = true;
            } catch (error) {
                console.error(error);
            }
        },

        async getLogs() {
            try {
                const res = await this.$store.dispatch('app/getLogs');
                this.logs = res.data.result;
            } catch (error) {
                console.error(error);
            }
        },

        switchSelected() {
            this.fetchTags();
            this.fetchFeaturesAndBranches();
        },
        fetchFeaturesAndBranches() {
            try {
                const selectedProject = this.projects.find((project) => project.name === this.project_name);
                if (selectedProject) {
                    const projectId = selectedProject.id;
                    this.$store.dispatch('app/getFeaturesAssociatedWithProject', projectId).then((res) => {
                        this.branches = res.data;
                    });
                } else {
                    throw new Error('Selected project not found');
                }
            } catch (error) {
                console.error(error);
            }
        },

        fetchTags() {
            try {
                const selectedProject = this.projects.find((project) => project.name === this.project_name);
                if (selectedProject) {
                    const projectId = selectedProject.id;

                    this.$store.dispatch('app/getProjectTags', projectId).then((res) => {
                        this.tags = res.data;
                    });
                } else {
                    throw new Error('Selected project not found');
                }
            } catch (error) {
                console.error(error);
            }
        },
        deploy() {
            const selectedProject = this.project_name;
            const selectedSupport = this.support_name.toLowerCase();
            const selectedBranch = this.selectedBranche.toLowerCase();

            const dataTobeSubmitted = {
                project_name: selectedProject,
                project_line: selectedSupport,
                feature_name: selectedBranch,
                project_tag: this.selectedTags,
            };

            this.loading = true;

            this.$store
                .dispatch('app/deployProject', dataTobeSubmitted)
                .then((res) => {
                    const statusCode = res.status;
                    let variant = 'success';
                    let title = res.data.message.comment;

                    if (statusCode !== 200 || res.data.message.exitCode != 0) {
                        variant = 'danger';
                        title = title;
                    }

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: title,
                            icon: 'smile',
                            variant: variant,
                        },
                    });
                })
                .catch((error) => {
                    if (error.response.status === 403) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Impossible de deployer depuis cet environement',
                                message: "Impossible de deployer depuis cet environement",
                                icon: 'exclamation-triangle',
                                variant: 'danger',
                            },
                        });
                    }
                    else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error',
                                message: error.error,
                                icon: 'exclamation-triangle',
                                variant: 'danger',
                            },
                        });
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.resetForm();
                    this.getLogs();
                });
        },

        resetForm() {
            this.project_name = '';
            this.support_name = '';
            this.selectedBranche = '';
            this.selectedTags = '';
        },

        async fetchPortListAndVersions() {
            try {
                const request = await this.$store.dispatch('app/getProjectsListandVersions');
                const res = request.data.result;
                this.portListAndVersions = res;
            } catch (error) { }
        },
    },
};
</script>

<style>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
